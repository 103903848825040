export const environment = {
    production: false,
    version: '24.2.0 (Test)',
    licenseTypeId: '202',
    auth_baseurl_server: 'https://axerrio-accounts-test.azurewebsites.net',
    tenant_baseurl_server: 'https://multitenant-test.azurewebsites.net',
    appInsights: {
        instrumentationKey: 'a97e5a6e-c318-4181-946e-dcd7213fb892',
    },
};
